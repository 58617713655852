
import { Component, Vue } from 'vue-property-decorator'
import { AddInfo, Tags, Managers, ExperienceData } from '../../types/experienceApply'
import { ElForm } from 'element-ui/types/form'
import { phone, email } from '@/utils/validate'

@Component({
  name: 'applyAdd'
})
export default class extends Vue {
  private submitShow = false
  private loading = false
  private businessTagList: Tags[] = []
  private accountManagerList: Managers[] = []
  private tableData = []
  private info: AddInfo = {
    applyName: '',
    phone: '',
    email: '',
    companyName: '',
    smId: '',
    customerId: '',
    source: '2',
    labelId: '1',
    tenantApplyProductList: []
  }

  private rules = {
    applyName: [{ required: true, message: '请输入客户名称', trigger: ['blur', 'change'] }],
    phone: [
      { required: true, message: '请输入联系方式', trigger: ['blur', 'change'] },
      { validator: phone, trigger: ['blur', 'change'] }
    ],
    email: [
      { validator: email, trigger: ['blur', 'change'] }
    ],
    companyName: [{ required: true, message: '请输入公司名称', trigger: ['blur', 'change'] }],
    smId: [{ required: true, message: '请选择客户经理', trigger: ['blur', 'change'] }],
    labelId: [{ required: true, message: '请选择业务标签', trigger: ['blur', 'change'] }],
    customerId: [{ required: true, message: '请选择系统账号', trigger: ['blur', 'change'] }]
  }

  get applyId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getBusinessTagList()
    this.getAccountManagerList()
    this.getData()
    if (this.applyId) {
      this.getDetail()
    }
  }

  // 获取业务标签列表
  getBusinessTagList () {
    this.$axios.get(this.$apis.experienceApply.selectTenantProductLabelByList).then(res => {
      this.businessTagList = res.list || []
    })
  }

  // 获取客户经理列表
  getAccountManagerList () {
    this.$axios.get(this.$apis.experienceApply.selectSmByList).then(res => {
      this.accountManagerList = res.list || []
    })
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.experienceApply.selectCustTProductByPage, { labelId: this.info.labelId }).then(res => {
      res.list.forEach((item: ExperienceData) => {
        if (item.type) {
          item.type === '1' ? item.isChoose = true : item.isChoose = false
        }
      })
      this.tableData = res.list || []
      this.tableData.forEach((item: ExperienceData) => {
        this.info.tenantApplyProductList.forEach((items: ExperienceData) => {
          if (item.productId === items.productId) {
            item.isChoose = true
          }
        })
      })
    }).finally(() => {
      this.loading = false
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.experienceApply.selectTenantApplyByApplyId, {
      applyId: this.applyId
    }).then(res => {
      this.info = res
      this.getData()
    })
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.info.tenantApplyProductList = []
        this.tableData.forEach((item: ExperienceData) => {
          if (item.isChoose) {
            this.info.tenantApplyProductList.push({
              productId: item.productId
            })
          }
        })
        this.submitShow = true
        const url = this.applyId ? this.$apis.experienceApply.updateTenantApply : this.$apis.experienceApply.insertTenantApply
        const sbuInfo = this.applyId ? {
          applyId: this.applyId,
          labelId: this.info.labelId,
          tenantApplyProductList: this.info.tenantApplyProductList
        } : this.info
        this.$axios.post(url, sbuInfo).then(() => {
          this.$message.success('保存成功')
          this.$router.push({ name: 'applyList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
